const LowStockAlert = () => {
    return (
        <svg
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            id="statistic-low"
            className="icon glyph">
            <path
                d="M22,10v5a1,1,0,0,1-.08.38,1,1,0,0,1-.54.54A1,1,0,0,1,21,16H16a1,1,0,0,1,0-2h2.59L13.8,9.22,8.45,11.89a1,1,0,0,1-1.22-.25l-5-6A1,1,0,0,1,3.77,4.36L8.26,9.75l5.29-2.64a1,1,0,0,1,1.16.18L20,12.59V10a1,1,0,0,1,2,0Zm-1,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                style={{ fill: '#231f20' }}></path>
        </svg>
    )
}

export default LowStockAlert
