import common from './common'
import featuresPay from './feature'

const mr = {
    featuresPay,
    common,
    short: {
        finance: 'आर्थिक',
        stock: 'स्टॉक'
    },
    onboarding: {
        welcome: 'स्वागत आहे!',
        step: {
            product: {
                title: '65% पूर्ण झाले',
                description: 'तुमच्या महान यशासाठी फक्त 2 पायऱ्या शिल्लक आहेत!'
            },
            stock: {
                title: '95% जवळपास पूर्ण!!',
                description: 'तुमच्या महान यशासाठी फक्त एक पायरी बाकी आहे! आता पूर्ण करा.'
            }
        },
        business: {
            title: 'व्यवसाय यशस्वीरित्या तयार झाला!',
            description: 'सुप्रभात! तुमचा व्यवसाय नोंदवला गेला आहे.'
        },
        product: {
            title: 'तुमचा पहिला उत्पादने जोडा',
            description: 'स्टॉक नोंदवायला प्रारंभ करण्यासाठी एक उत्पादन जोडा.'
        },
        stock: {
            title: 'स्टॉक जोडण्याचा प्रयत्न करा',
            description: 'आगामी आणि बाहेर जाणाऱ्या स्टॉकची नोंद ठेवायला प्रारंभ करा.'
        },
        action: {
            add_business: {
                title: 'व्यवसाय तयार करा',
                description: 'नवीन व्यवसाय तयार करू इच्छिता? इथे क्लिक करा.'
            },
            join_business: {
                title: 'व्यवसायात सामील व्हा',
                description: 'कोणती आमंत्रण कोड आहे का? इथे सामील व्हा.'
            }
        }
    },
    business: {
        title: 'व्यवसाय निवडा, नवीन तयार करा, किंवा सामील व्हा',
        add_business: {
            title: 'नवीन व्यवसाय तयार करा',
            name: 'व्यवसायाचे नाव',
            name_placeholder: 'नाव भरा',
            category: 'व्यवसाय श्रेणी',
            category_placeholder: 'श्रेणी निवडा',
            button: 'व्यवसाय तयार करा'
        },
        edit_business: {
            title: 'व्यवसाय संपादित करा'
        },
        join_business: {
            title: 'व्यवसायात सामील व्हा',
            input_code: 'खालील आमंत्रण कोड भरा:',
            field_placeholder: 'आमंत्रण कोड',
            confirm_title: 'सामील व्हा',
            confirm_text: 'तुम्ही येथे टीमचा भाग बनाल '
        },
        no_business: 'कोणताही व्यवसाय उपलब्ध नाही',
        create_or_join: 'व्यवसाय तयार करा किंवा सामील व्हा',
        message: {
            saved: 'व्यवसाय यशस्वीपणे जतन केला!'
        }
    },
    profile: {
        edit_profile: {
            title: 'प्रोफाइल संपादित करा',
            name: 'पूर्ण नाव',
            name_placeholder: 'पूर्ण नाव भरा',
            email: 'ईमेल',
            phone_number: 'फोन नंबर'
        },
        message: {
            required_name: 'नाव आवश्यक आहे'
        }
    },
    product: {
        choose_product: 'उत्पादन निवडा',
        choose_product_empty: 'उत्पादन निवडा आणि प्रमाण प्रविष्ट करा',
        search: {
            placeholder: 'नावाने शोधा',
            tooltip: 'स्कॅनर उघडा'
        },
        column: {
            image: 'फोटो',
            name: 'नाव',
            price: 'किंमत',
            buying_price: 'खरेदी किंमत',
            selling_price: 'विक्री किंमत',
            sku: 'SKU',
            stock: 'स्टॉक',
            quantity: 'प्रमाण',
            latest_stock: 'उर्वरित',
            product_deleted: 'उत्पादन काढले गेले आहे',
            info_not_found: 'माहिती उपलब्ध नाही',
            expired_date: 'कालबाह्य तारीख'
        },
        no_product: 'उत्पादने उपलब्ध नाहीत',
        add_product: {
            sku: 'उत्पादन कोड',
            sku_placeholder: 'उत्पादन कोड. उदाहरण: 12345',
            name: 'उत्पादनाचे नाव',
            name_placeholder: 'उत्पादनाचे नाव प्रविष्ट करा',
            initial_stock: 'प्रारंभिक स्टॉक',
            initial_stock_placeholder: 'प्रारंभिक स्टॉक प्रविष्ट करा',
            buying_price: 'खरेदी किंमत',
            buying_price_placeholder: 'खरेदी किंमत प्रविष्ट करा',
            selling_price: 'विक्री किंमत',
            selling_price_placeholder: 'विक्री किंमत प्रविष्ट करा',
            category: 'श्रेणी',
            category_placeholder: 'श्रेणी निवडा',
            add_selling_price: 'थोक किंमत जोडा',
            expired_date: 'कालबाह्य तारीख',
            expired_date_placeholder: 'तारीख निवडा',
            description: 'वर्णन',
            description_placeholder: 'वर्णन प्रविष्ट करा'
        },
        choose_selling_price: 'विक्री किंमत निवडा',
        selling_price: 'विक्री किंमत',
        total_product: 'एकूण उत्पादने',
        total_quantity: 'एकूण स्टॉक',
        total_buying_price: 'एकूण खरेदी किंमत',
        total_selling_price: 'एकूण विक्री किंमत',
        message: {
            required_name: 'उत्पादनाचे नाव आवश्यक आहे',
            required_sku: 'SKU आवश्यक आहे',
            required_category: 'श्रेणी आवश्यक आहे',
            required_quantity: 'प्रारंभिक स्टॉक आवश्यक आहे',
            invalid_input_qty: 'अवैध स्टॉक प्रमाण',
            required_buying_price: 'खरेदी किंमत आवश्यक आहे',
            required_selling_price: 'विक्री किंमत आवश्यक आहे',
            required_selling_price_name: 'विक्री किंमत नाव आवश्यक आहे',
            required_selling_price_price: 'विक्री किंमत आवश्यक आहे'
        }
    },
    note: {
        title: 'नोट्स',
        add: 'नोट जोडा',
        no_note: 'कोणतीही नोट्स उपलब्ध नाहीत'
    },
    settings: {
        category: 'श्रेणी',
        language: 'भाषा',
        switch_business: 'व्यवसाय बदला',
        currencySelect: 'चलन'
    },
    category: {
        category_list: {
            title: 'उत्पादन श्रेण्या'
        },
        add_category: {
            button: 'नवीन श्रेणी',
            title: 'नवीन श्रेणी जोडा'
        },
        edit_category: {
            title: 'श्रेणी संपादित करा'
        },
        name: 'श्रेणीचे नाव',
        name_placeholder: 'श्रेणीचे नाव टाका',
        no_category: 'कोणतीही श्रेणी उपलब्ध नाही'
    },
    staff: {
        add: 'कर्मचारी जोडा',
        edit: 'कर्मचारी संपादित करा',
        access: {
            stock: 'स्टॉक',
            add_product: 'नवीन उत्पादन जोडा',
            edit_product: 'उत्पादन संपादित करा',
            delete_product: 'उत्पादन काढा',
            view_capital_price: 'खर्च किंमत पहा',
            add_category: 'नवीन श्रेणी जोडा',
            edit_category: 'श्रेणी संपादित करा',
            delete_category: 'श्रेणी काढा',
            add_supplier: 'नवीन विक्रेता जोडा',
            edit_supplier: 'विक्रेता संपादित करा',
            delete_supplier: 'विक्रेता काढा',
            add_customer: 'नवीन ग्राहक जोडा',
            edit_customer: 'ग्राहक संपादित करा',
            delete_customer: 'ग्राहक काढा',
            add_staff: 'कर्मचारी जोडा',
            edit_staff: 'कर्मचारी संपादित करा',
            delete_staff: 'कर्मचारी काढा',
            change_business_profile: 'व्यवसाय प्रोफाइल बदला'
        },
        no_staff: 'कोणतीही कर्मचारी उपलब्ध नाही.'
    },
    code: {
        title: 'नवीन कर्मचारी जोडले गेले!',
        subtitle: 'जोडण्यासाठी खालील आमंत्रण कोड सामायिक करा:',
        valid: 'कोड 48 तासांसाठी वैध आहे',
        share: 'व्हाट्सअँपवर सामायिक करा',
        copied: 'कॉपी झाले!',
        invalid: 'अवैध कोड!'
    },
    history: {
        no_history: 'कोणतीही इतिहास उपलब्ध नाही.',
        detail: {
            remove: 'इतिहास काढा',
            confirm: 'या वैशिष्ट्याचा परिणाम विद्यमान स्टॉक, इतिहास, आणि अहवालांवर होईल.'
        }
    },
    supplier: {
        add: {
            title: 'विक्रेता जोडा'
        },
        edit: {
            title: 'विक्रेता संपादित करा'
        },
        new: 'नवीन विक्रेता',
        no_supplier: 'कोणतेही विक्रेता उपलब्ध नाहीत.',
        bank: {
            title: 'बँक तपशील'
        },
        note: 'टीका',
        form: {
            name: {
                label: 'विक्रेत्याचे नाव',
                placeholder: 'विक्रेत्याचे नाव भरा',
                placheolder_not_mandatory: 'नाव भरा (ऐच्छिक)',
                error: {
                    required: 'नाव रिक्त असू शकत नाही'
                }
            },
            contact: {
                label: 'संपर्क क्रमांक',
                placeholder: '91934554432',
                error: {
                    required: 'संपर्क क्रमांक रिक्त असू शकत नाही',
                    invalid: 'अवैध क्रमांक. देशाचा कोड समाविष्ट असावा'
                }
            },
            bank_name: {
                label: 'बँक नाव',
                placeholder: 'बँक नाव भरा'
            },
            bank_account_name: {
                label: 'खातेधारकाचे नाव',
                placeholder: 'खातेधारकाचे नाव भरा'
            },
            bank_account_number: {
                label: 'खाते क्रमांक',
                placeholder: 'खाते क्रमांक भरा'
            },
            note: {
                label: 'टीका',
                placeholder: 'टीका जोडा'
            }
        },
        choose: 'विक्रेता निवडा'
    },
    customer: {
        add: {
            title: 'ग्राहक जोडा'
        },
        edit: {
            title: 'ग्राहक संपादित करा'
        },
        new: 'नवीन ग्राहक',
        no_customer: 'कोणतेही ग्राहक उपलब्ध नाहीत.',
        note: 'टीका',
        form: {
            name: {
                label: 'ग्राहकाचे नाव',
                placeholder: 'ग्राहकाचे नाव भरा',
                placheolder_not_mandatory: 'नाव भरा (ऐच्छिक)',
                error: {
                    required: 'नाव रिक्त असू शकत नाही'
                }
            },
            contact: {
                label: 'संपर्क क्रमांक',
                placeholder: '91934554432',
                error: {
                    required: 'संपर्क क्रमांक रिक्त असू शकत नाही',
                    invalid: 'अवैध क्रमांक. देशाचा कोड समाविष्ट असावा'
                }
            },
            address: {
                label: 'पत्ता',
                placeholder: 'पत्ता भरा'
            },
            note: {
                label: 'टीका',
                placeholder: 'टीका जोडा'
            }
        },
        choose: 'खरेदीदार निवडा',
        report: {
            view: 'खरेदीदार अहवाल पहा',
            title: 'खरेदीदार अहवाल',
            no_report: 'निवडलेल्या तारखेसाठी खरेदीदारांचा कोणताही अहवाल नाही.',
            column: {
                name: 'नाव',
                total_transaction: 'एकूण व्यवहार',
                total_amount: 'एकूण विक्री',
                total_profit: 'एकूण नफा'
            }
        }
    },
    confirm_delete: 'तुम्हाला हे डेटा वगळायचे आहे का?',
    report: {
        title: 'अहवाल'
    },
    accounting: {
        title: 'लेखांकन',
        view_report: 'आर्थिक अहवाल पहा',
        dashboard_title: 'आर्थिक अहवाल',
        stats: 'आकडेवारी',
        transaction_report: 'व्यवहार अहवाल',
        filter: {
            date: {
                placeholder: 'तारीख निवडा',
                submit: 'लागू करा',
                cancel: 'फिल्टर रीसेट करा'
            },
            timeframe: {
                day: 'दैनिक',
                week: 'साप्ताहिक',
                month: 'मासिक',
                year: 'वार्षिक'
            }
        },
        total_sales: 'एकूण विक्री',
        total_purchases: 'एकूण खरेदी',
        total_profit: 'एकूण नफा',
        assets_value: 'सध्याची मालमत्ता मूल्य',
        table: {
            date: 'तारीख',
            sales: 'विक्री',
            purchases: 'खरेदी',
            profit: 'नफा'
        },
        no_data: 'डेटा उपलब्ध नाही.'
    },
    stock_report: {
        title: 'स्टॉक अहवाल',
        view: 'स्टॉक अहवाल पहा',
        no_report: 'निवडक तारीख किंवा कर्मचार्‍यांसाठी कोणतेही स्टॉक रेकॉर्ड नाहीत.',
        allStaffPlaceHolder: 'सर्व कर्मचारी',
        column: {
            total_product: 'सध्याचे उत्पादन प्रकार',
            total_product_quantity: 'एकूण सध्याचे उत्पादने'
        }
    },
    subscription: {
        paywall_small: {
            title: 'VIP फीचर',
            description:
                'या फीचरचा तुमचा चाचणी कालावधी संपला आहे. सदस्यता घेऊन या स्थानिक अनुप्रयोगाच्या विकासाला समर्थन द्या.',
            button: 'सदस्यता योजना पहा'
        }
    },
    payment: {
        order: {
            title: 'भुगतान'
        }
    },
    imageUploadMessage: {
        uploadSize: 'प्रतिमा आकार 5MB पेक्षा जास्त नसावा!',
        failedMessage: 'आइटमची प्रतिमा अपलोड करण्यात अयशस्वी, कृपया पुन्हा प्रयत्न करा!',
        successUpload: 'प्रतिमा यशस्वीरित्या अपलोड केली गेली!',
        failedUIError: 'प्रतिमा हटवण्यात अयशस्वी, कृपया पुन्हा प्रयत्न करा!',
        SuccessErrorImage: 'प्रतिमा यशस्वीरित्या हटवली गेली!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'उत्पादनाचे नाव',
        categoryLabel: 'श्रेणी',
        BuyingPriceLabel: 'खरेदी किंमत',
        SellingLabel: 'विक्री किंमत',
        StockLabel: 'स्टॉक'
    },
    historyColumns: {
        Transactionlabel: 'व्यवहार प्रकार',
        Locallabel: 'स्थानिक वेळ',
        Createdlabel: 'निर्माण केले',
        Vendorlabel: 'विक्रेत्याचे नाव',
        Buyerlabel: 'खरेदीदाराचे नाव',
        SKUlabel: 'SKU',
        Productlabel: 'उत्पादनाचे नाव',
        Categorylabel: 'श्रेणी',
        Buyinglabel: 'खरेदी किंमत',
        Sellinglabel: 'विक्री किंमत',
        Initiallabel: 'प्रारंभिक स्टॉक',
        Finallabel: 'अंतिम स्टॉक',
        Stocklabel: 'स्टॉक बदल'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'उत्पादनाचे नाव',
        categoryLabel: 'श्रेणी',
        buyingPriceLabel: 'खरेदी किंमत',
        sellingPriceLabel: 'विक्री किंमत',
        stockInLabel: 'स्टॉक मध्ये',
        stockOutLabel: 'स्टॉक बाहेर',
        remainingStockLabel: 'उपलब्ध स्टॉक'
    },
    accountingReportColumns: {
        labelDate: 'तारीख',
        labelSales: 'विक्री',
        labelPurchases: 'खरेदी',
        labelProfit: 'लाभ'
    },
    customerReportColumns: {
        labelName: 'नाव',
        labelTotalTransaction: 'एकूण व्यवहार',
        labelTotalSales: 'एकूण विक्री',
        labelTotalProfit: 'एकूण लाभ'
    },
    scannerDialog: {
        finished: 'पूर्ण झाले',
        reduce: 'कमी करा',
        successfull: 'यशस्वी',
        searching: 'शोधत आहे...',
        title: 'स्कॅनर सक्रिय आहे. बारकोडकडे लक्ष ठेवा!'
    },
    historyFilter: {
        SelectBuyer: 'खरेदीदार निवडा',
        SelectVendor: 'विक्रेता निवडा'
    },
    productStockFilter: {
        StockLowtoHigh: 'किमतीनुसार कमी ते जास्त',
        StockHightoLow: 'किमतीनुसार जास्त ते कमी',
        AlphabetAZ: 'अक्षरानुसार A-Z',
        AlphabetZA: 'अक्षरानुसार Z-A'
    },
    minimizeTitle: 'स्कॅनर सक्रिय आहे',
    security_guarantee: {
        title: 'डेटा सुरक्षा हमी'
    },
    invoice: {
        invoicePlaceholder: 'चलन क्रमांक',
        invoiceTitle: 'चलन'
    },
    stocklowreport: {
        no_stocklowreport_one: 'सुरक्षित साठा सेटिंग्जसह कोणतीही वस्तू नाहीत.',
        no_stocklowreport_two: 'कृपया सुरक्षित साठ्याची संख्या जोडा.'
    },
    low_alert_stock: 'सुरक्षित साठ्याची संख्या प्रविष्ट करा',
    lowStockAlertNotify: 'जेव्हा साठा निश्चित केलेल्या संख्येपेक्षा कमी असेल तेव्हा तुम्हाला सूचना मिळेल.',
    applyButtonStockAlert: 'लागू करा',
    cancelButtonStockAlert: 'रद्द करा',
    searchProductLabel: 'उत्पादन शोधा...',
    successfullyAddedSafetyStock: 'सुरक्षित साठा यशस्वीरित्या अद्यतनित केला',
    safety_Stock: 'सुरक्षा स्टॉक',
    safety_stock_placeholder: 'सुरक्षा स्टॉक प्रविष्ट करा',
    lowStockDetailsInfo: {
        ProductCode: 'उत्पादन कोड',
        SafetyStock: 'सुरक्षा साठा',
        Stock: 'साठा',
        CostPrice: 'किंमत खर्च',
        SellingPrice: 'विक्री किंमत',
        Category: 'वर्ग',
        Description: 'वर्णन',
        ExpirationDate: 'समाप्ती तारीख'
    },
    CustomLang: {
        Attribute: 'गुणधर्म',
        AddAttribute: 'गुणधर्म जोडा',
        EditAttribute: 'गुणधर्म संपादित करा',
        CustomLabePlaceholder: 'गुणधर्माचे नाव टाका',
        CustomLabePlaceholderSelect: 'प्रकार निवडा',
        AddMoreAttribute: 'अधिक गुणधर्म जोडा',
        ItemAttribute: 'आयटम गुणधर्म',
        TableName: 'नाव',
        TableType: 'प्रकार',
        TableAction: 'क्रिया',
        TableDelete: 'हटवा',
        TableEdit: 'संपादित करा',
        deleteMessage: 'गुणधर्म यशस्वीरित्या हटवला',
        editSuccessMessage: 'गुणधर्म यशस्वीरित्या अद्यतनित केला',
        AddSuccessMessage: 'गुणधर्म यशस्वीरित्या जोडला',
        AddAtt: 'जोडा',
        EditAtt: 'संपादित करा',
        SubmitAtt: 'सबमिट करत आहे...'
    }
}

export default mr
