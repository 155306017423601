const common = {
    no_data: 'کوئی ڈیٹا دستیاب نہیں',
    header: {
        business_name: 'آپ کا کاروبار'
    },
    download_app: {
        text: 'سمپلی ویب ورژن استعمال کرنے کے لیے، براہ کرم لیپ ٹاپ یا پی سی استعمال کریں۔',
        button: 'موبائل ورژن ڈاؤنلوڈ کریں'
    },
    unknown: 'نامعلوم',
    request_error:
        'ایک خرابی پیش آگئی۔ براہ کرم دوبارہ کوشش کریں! یا اپنے مسئلے کی اطلاع دینے کے لیے کسٹمر سپورٹ سے رابطہ کریں۔',
    home: 'ہوم',
    product: 'پروڈکٹ',
    add_product: 'پروڈکٹ شامل کریں',
    edit_product: 'پروڈکٹ میں ترمیم کریں',
    stock_in: 'اسٹاک اِن',
    Sales_Turnover: 'سیلز ٹرن اوور',
    profit: 'منافع',
    stock_out: 'اسٹاک آؤٹ',
    staff: 'عملہ',
    stock_audit: 'اسٹاک آڈٹ',
    button: {
        save: 'محفوظ کریں',
        save_and_add_more: 'محفوظ کریں اور مزید شامل کریں',
        submit: 'جمع کریں',
        done: 'ہو گیا',
        edit: 'ترمیم کریں',
        save_edit: 'تبدیلیاں محفوظ کریں',
        yes: 'ہاں',
        clear: 'صاف کریں',
        delete: 'حذف کریں',
        cancel: 'منسوخ کریں',
        reset: 'ری سیٹ کریں'
    },
    select_date: 'تاریخ منتخب کریں',
    select_action: 'عمل منتخب کریں',
    mandatory: ' *(ضروری)',
    not_mandatory: '(غیر ضروری)',
    business: 'کاروبار',
    account: 'اکاؤنٹ',
    profile: 'پروفائل',
    setting: 'ترتیبات',
    whichLanguage: 'اردو',
    headerDashboard: 'وی آئی پی بنیں',
    contact_us: 'ہم سے رابطہ کریں',
    logout: 'لاگ آؤٹ',
    category: 'زمرہ',
    data: 'ڈیٹا',
    history: 'تاریخ',
    lowStockAlert: 'کم اسٹاک کی اطلاع',
    lowStockAlertButton: 'محفوظ اسٹاک کی مقدار شامل کریں',
    message: {
        login_success: 'کامیابی سے لاگ ان ہو گیا!',
        onboard_success: 'واہ! آپ کا سفر شروع ہو گیا!',
        successfully_saved: 'ڈیٹا کامیابی سے محفوظ ہو گیا',
        successfully_deleted: 'ڈیٹا کامیابی سے حذف کر دیا گیا',
        error_required_field: 'ضروری فیلڈ خالی نہیں ہو سکتی',
        no_access: 'رسائی نہیں',
        error_update_history: 'تاریخ کو اپ ڈیٹ کرنے میں ناکام'
    },
    feedback: 'رائے دیں',
    contact: 'رابطہ',
    add: 'شامل کریں',
    supplier: 'سپلائر',
    customer: 'صارف',
    random: 'رینڈم',
    scan: 'سکین کریں',
    enter: 'بارکوڈ سکین کر کے لاگ ان کریں',
    terms: {
        text: 'جاری رکھتے ہوئے، آپ اتفاق کرتے ہیں',
        link: 'رازداری کی پالیسی'
    },
    others: 'دیگر',
    calender: {
        presets: {
            today: 'آج',
            yesterday: 'گزشتہ روز',
            last_7_days: 'گزشتہ 7 دن',
            last_30_days: 'گزشتہ 30 دن',
            this_month: 'اس مہینے',
            last_month: 'گزشتہ مہینہ',
            custom_range: 'حسب ضرورت مدت'
        }
    },
    print_large: 'بڑا پرنٹ (A4)',
    print_dot_matrix: 'بڑا پرنٹ (ڈاٹ میٹرکس)',
    print_medium: 'درمیانہ پرنٹ (75mm)',
    print_small: 'چھوٹا پرنٹ (48mm)',
    view_invoice: 'انویس',
    price: 'قیمت',
    stock: 'اسٹاک',
    quantity: 'مقدار',
    total: 'کل',
    print_footer_left: 'بنایا گیا',
    print_footer_center: 'پرنٹ کیا گیا',
    print_footer_date: 'تاریخ',
    print_to: 'پر',
    language: 'اردو',
    dateStatus: 'گزشتہ 7 دن',
    placeholder: 'ٹرانزیکشن کی قسم',
    staffHistory: 'عملہ منتخب کریں',
    languageTitle: 'اردو',
    date: 'تاریخ',
    download: 'ڈاؤنلوڈ',
    download_excel: 'ایکسسل ڈاؤنلوڈ کریں',
    currentTitle: 'کرنسی',
    Selectperiod: 'مدت کا انتخاب کریں',
    TransactionType: 'ٹرانزیکشن کی قسم',
    SelectCustomer: 'صارف منتخب کریں',
    invoice: 'انویس',
    login: {
        google: 'گوگل کے ساتھ لاگ ان کریں',
        apple: 'ایپل کے ساتھ لاگ ان کریں'
    },
    trusted_by: {
        title: 'انڈونیشیا کے 50,000 سے زیادہ کاروباروں کے ذریعہ اعتماد کیا گیا 🇮🇩'
    },
    privacyPolicyOne: 'لاگ ان کرنے کے ذریعے، آپ Simply کی',
    privacyPolicyTwo: 'پرائیویسی پالیسی',
    privacyPolicyThree: 'سے اتفاق کرتے ہیں۔'
}

export default common
