import common from './common'
import featuresPay from './feature'

const kn = {
    featuresPay,
    common,
    short: {
        finance: 'ಹಣಕಾಸು',
        stock: 'ಸ್ಟಾಕ್'
    },
    onboarding: {
        welcome: 'ಸ್ವಾಗತ!',
        step: {
            product: {
                title: '65% ಸಾಧಿತವಾಗಿದೆ',
                description: 'ನಿಮ್ಮ ಮಹಾನ್ ಯಶಸ್ಸು ಪ್ರಾರಂಭಿಸಲು ಕೇವಲ 2 ಹಂತಗಳು ಉಳಿದಿವೆ!'
            },
            stock: {
                title: '95% Almost Done!!',
                description: 'ನಿಮ್ಮ ಮಹಾನ್ ಯಶಸ್ಸು ಪ್ರಾರಂಭಿಸಲು ಕೇವಲ ಇನ್ನೊಂದು ಹಂತ ಬಾಕಿಯಿದೆ! ಇದನ್ನು ಈಗ ಸಂಪೂರ್ಣಗೊಳಿಸಿ.'
            }
        },
        business: {
            title: 'ವ್ಯಾಪಾರ ಯಶಸ್ವಿಯಾಗಿ ಸೃಷ್ಟಿಸಲಾಗಿದೆ!',
            description: 'ಅద్భುತ! ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ದಾಖಲಿಸಲಾಗಿದೆ'
        },
        product: {
            title: 'ನಿಮ್ಮ ಮೊದಲ ಉತ್ಪನ್ನವನ್ನು ಸೇರಿಸಿ',
            description: 'ಸ್ಟಾಕ್ ದಾಖಲಿಸಲು ಉತ್ಪನ್ನವನ್ನು ಸೇರಿಸಿ'
        },
        stock: {
            title: 'ಸ್ಟಾಕ್ ಸೇರಿಸಲು ಪ್ರಯತ್ನಿಸಿ',
            description: 'ಬಳಕೆದಾರ ಮತ್ತು ಹೊರಹೋಗುವ ಸ್ಟಾಕ್ ಅನ್ನು ದಾಖಲಿಸಲು ಪ್ರಾರಂಭಿಸಿ'
        },
        action: {
            add_business: {
                title: 'ವ್ಯಾಪಾರವನ್ನು ರಚಿಸಿ',
                description: 'ಹೊಸ ವ್ಯಾಪಾರವನ್ನು ರಚಿಸಲು ಬಯಸುತ್ತೀರಾ? ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ'
            },
            join_business: {
                title: 'ವ್ಯಾಪಾರಕ್ಕೆ ಸೇರಿ',
                description: 'ಆಹ್ವಾನ ಕೋಡ್ ಇದೆಯೇ? ಇಲ್ಲಿ ಸೇರಿ'
            }
        }
    },
    business: {
        title: 'ಒಬ್ಬ ವ್ಯಾಪಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡು, ಹೊಸದು ಸೃಷ್ಟಿಸು ಅಥವಾ ಸೇರಿ',
        add_business: {
            title: 'ಹೊಸ ವ್ಯಾಪಾರವನ್ನು ರಚಿಸಿ',
            name: 'ವ್ಯಾಪಾರದ ಹೆಸರು',
            name_placeholder: 'ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
            category: 'ವ್ಯಾಪಾರ ಶ್ರೇಣಿ',
            category_placeholder: 'ಶ್ರೇಣಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡು',
            button: 'ವ್ಯಾಪಾರವನ್ನು ರಚಿಸಿ'
        },
        edit_business: {
            title: 'ವ್ಯಾಪಾರವನ್ನು ಸಂಪಾದಿಸಿ'
        },
        join_business: {
            title: 'ವ್ಯಾಪಾರಕ್ಕೆ ಸೇರಿ',
            input_code: 'ಕೆಳಗಿನ ಆಹ್ವಾನ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ:',
            field_placeholder: 'ಆಹ್ವಾನ ಕೋಡ್',
            confirm_title: 'ಸೇರಿ',
            confirm_text: 'ನೀವು ತಂಡದ ಭಾಗವಾಗುತ್ತೀರಿ '
        },
        no_business: 'ಯಾವುದೇ ವ್ಯಾಪಾರ ಲಭ್ಯವಿಲ್ಲ',
        create_or_join: 'ವ್ಯಾಪಾರವನ್ನು ರಚಿಸಿ ಅಥವಾ ಸೇರಿ',
        message: {
            saved: 'ವ್ಯಾಪಾರ ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ!'
        }
    },
    profile: {
        edit_profile: {
            title: 'ಪ್ರೊಫೈಲ್ ಸಂಪಾದನೆ',
            name: 'ಮಂಪಡುವ ಹೆಸರು',
            name_placeholder: 'ಪೂರ್ಣ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
            email: 'ಇಮೇಲ್',
            phone_number: 'ದೂರವಾಣಿ ಸಂಖ್ಯೆ'
        },
        message: {
            required_name: 'ಹೆಸರು ಅಗತ್ಯವಿದೆ'
        }
    },
    product: {
        choose_product: 'ಉತ್ಪನ್ನ ಆಯ್ಕೆ ಮಾಡಿ',
        choose_product_empty: 'ಉತ್ಪನ್ನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ ಮತ್ತು ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ',
        search: {
            placeholder: 'ಹೆಸರು ಆಧಾರದ ಮೇಲೆ ಹುಡುಕಿ',
            tooltip: 'ಸ್ಕ್ಯಾನರ್ ತೆರೆಯಿರಿ'
        },
        column: {
            image: 'ಫೋಟೋ',
            name: 'ಹೆಸರು',
            price: 'ಬೆಲೆ',
            buying_price: 'ಖರೀದಿ ಬೆಲೆ',
            selling_price: 'ಮಾರಾಟದ ಬೆಲೆ',
            sku: 'SKU',
            stock: 'ಸ್ಟಾಕ್',
            quantity: 'ಪ್ರಮಾಣ',
            latest_stock: 'ಬಾಕಿ',
            product_deleted: 'ಉತ್ಪನ್ನವನ್ನು ಅಳಿಸಲಾಗಿದೆ',
            info_not_found: 'ಮಾಹಿತಿ ಲಭ್ಯವಿಲ್ಲ',
            expired_date: 'ಅವಧಿ ಮುಗಿಯುವ ದಿನಾಂಕ'
        },
        no_product: 'ಉತ್ಪನ್ನಗಳು ಲಭ್ಯವಿಲ್ಲ',
        add_product: {
            sku: 'ಉತ್ಪನ್ನ ಕೋಡ್',
            sku_placeholder: 'ಉತ್ಪನ್ನ ಕೋಡ್. ಉದಾಹರಣೆಗೆ: 12345',
            name: 'ಉತ್ಪನ್ನದ ಹೆಸರು',
            name_placeholder: 'ಉತ್ಪನ್ನದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
            initial_stock: 'ಆರಂಭಿಕ ಸ್ಟಾಕ್',
            initial_stock_placeholder: 'ಆರಂಭಿಕ ಸ್ಟಾಕ್ ಅನ್ನು ನಮೂದಿಸಿ',
            buying_price: 'ಖರೀದಿ ಬೆಲೆ',
            buying_price_placeholder: 'ಖರೀದಿ ಬೆಲೆ ನಮೂದಿಸಿ',
            selling_price: 'ಮಾರಾಟದ ಬೆಲೆ',
            selling_price_placeholder: 'ಮಾರಾಟದ ಬೆಲೆ ನಮೂದಿಸಿ',
            category: 'ವರ್ಗ',
            category_placeholder: 'ವರ್ಗ ಆಯ್ಕೆ ಮಾಡಿ',
            add_selling_price: 'ಮಾಸಿಕ ಬೆಲೆ ಸೇರಿಸಿ',
            expired_date: 'ಅವಧಿ ಮುಗಿಯುವ ದಿನಾಂಕ',
            expired_date_placeholder: 'ದಿನಾಂಕ ಆಯ್ಕೆ ಮಾಡಿ',
            description: 'ವಿವರಣೆ',
            description_placeholder: 'ವಿವರಣೆ ನಮೂದಿಸಿ'
        },
        choose_selling_price: 'ಮಾರಾಟದ ಬೆಲೆ ಆಯ್ಕೆ ಮಾಡಿರಿ',
        selling_price: 'ಮಾರಾಟದ ಬೆಲೆ',
        total_product: 'ಒಟ್ಟು ಉತ್ಪನ್ನಗಳು',
        total_quantity: 'ಒಟ್ಟು ಸ್ಟಾಕ್',
        total_buying_price: 'ಒಟ್ಟು ಖರೀದಿ ಬೆಲೆ',
        total_selling_price: 'ಒಟ್ಟು ಮಾರಾಟದ ಬೆಲೆ',
        message: {
            required_name: 'ಉತ್ಪನ್ನದ ಹೆಸರನ್ನು ನೀಡಬೇಕು',
            required_sku: 'SKU ಅಗತ್ಯವಿದೆ',
            required_category: 'ವರ್ಗ ಅಗತ್ಯವಿದೆ',
            required_quantity: 'ಆರಂಭಿಕ ಸ್ಟಾಕ್ ಅಗತ್ಯವಿದೆ',
            invalid_input_qty: 'ಅಸಾಕ್ಷಮ ಸ್ಟಾಕ್ ಪ್ರಮಾಣ',
            required_buying_price: 'ಖರೀದಿ ಬೆಲೆ ಅಗತ್ಯವಿದೆ',
            required_selling_price: 'ಮಾರಾಟದ ಬೆಲೆ ಅಗತ್ಯವಿದೆ',
            required_selling_price_name: 'ಮಾರಾಟದ ಬೆಲೆಯ ಹೆಸರನ್ನು ನೀಡಬೇಕು',
            required_selling_price_price: 'ಮಾರಾಟದ ಬೆಲೆ ಅಗತ್ಯವಿದೆ'
        }
    },
    note: {
        title: 'ನೋಟ್‌ಗಳು',
        add: 'ನೋಟ್ ಸೇರಿಸಿ',
        no_note: 'ಯಾವುದೇ ನೋಟ್ ಲಭ್ಯವಿಲ್ಲ'
    },
    settings: {
        category: 'ವರ್ಗ',
        language: 'ಭಾಷೆ',
        switch_business: 'ವ್ಯಾಪಾರವನ್ನು ಬದಲಾಯಿಸಿ',
        currencySelect: 'ನಾಣ್ಯ'
    },
    category: {
        category_list: {
            title: 'ಉತ್ಪನ್ನ ವರ್ಗಗಳು'
        },
        add_category: {
            button: 'ಹೊಸ ವರ್ಗ',
            title: 'ಹೊಸ ವರ್ಗ ಸೇರಿಸಿ'
        },
        edit_category: {
            title: 'ವರ್ಗವನ್ನು ಸಂಪಾದಿಸಿ'
        },
        name: 'ವರ್ಗದ ಹೆಸರು',
        name_placeholder: 'ವರ್ಗದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
        no_category: 'ಯಾವುದೇ ವರ್ಗಗಳಿಲ್ಲ'
    },
    staff: {
        add: 'ಸಿಬ್ಬಂದಿಯನ್ನು ಸೇರಿಸಿ',
        edit: 'ಸಿಬ್ಬಂದಿಯನ್ನು ಸಂಪಾದಿಸಿ',
        access: {
            stock: 'ಸ್ತೋಕು',
            add_product: 'ಹೊಸ ಉತ್ಪನ್ನವನ್ನು ಸೇರಿಸಿ',
            edit_product: 'ಉತ್ಪನ್ನವನ್ನು ಸಂಪಾದಿಸಿ',
            delete_product: 'ಉತ್ಪನ್ನವನ್ನು ಅಳಿಸಿ',
            view_capital_price: 'ಖರ್ಚು ಬೆಲೆಯನ್ನು ನೋಡಿ',
            add_category: 'ಹೊಸ ವರ್ಗವನ್ನು ಸೇರಿಸಿ',
            edit_category: 'ವರ್ಗವನ್ನು ಸಂಪಾದಿಸಿ',
            delete_category: 'ವರ್ಗವನ್ನು ಅಳಿಸಿ',
            add_supplier: 'ಹೊಸ ಮಾರಾಟಕರನ್ನು ಸೇರಿಸಿ',
            edit_supplier: 'ಮಾರಾಟಕರನ್ನು ಸಂಪಾದಿಸಿ',
            delete_supplier: 'ಮಾರಾಟಕರನ್ನು ಅಳಿಸಿ',
            add_customer: 'ಹೊಸ ಗ್ರಾಹಕವನ್ನು ಸೇರಿಸಿ',
            edit_customer: 'ಗ್ರಾಹಕವನ್ನು ಸಂಪಾದಿಸಿ',
            delete_customer: 'ಗ್ರಾಹಕವನ್ನು ಅಳಿಸಿ',
            add_staff: 'ಸಿಬ್ಬಂದಿಯನ್ನು ಸೇರಿಸಿ',
            edit_staff: 'ಸಿಬ್ಬಂದಿಯನ್ನು ಸಂಪಾದಿಸಿ',
            delete_staff: 'ಸಿಬ್ಬಂದಿಯನ್ನು ಅಳಿಸಿ',
            change_business_profile: 'ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಬದಲಾಯಿಸಿ'
        },
        no_staff: 'ಯಾವುದೇ ಸಿಬ್ಬಂದಿ ಲಭ್ಯವಿಲ್ಲ.'
    },
    code: {
        title: 'ಹೊಸ ಸಿಬ್ಬಂದಿ ಸೇರಿಸಲಾಗಿದೆ!',
        subtitle: 'ಕೂಡಲು ಕೀಳ್ಮಟ್ಟದ ಕೇಂದ್ರೀಕೃತ ಕೋಡ್ ಹಂಚಿಕೊಳ್ಳಿ:',
        valid: 'ಕೋಡ್ 48 ಗಂಟೆಗಳ ಕಾಲ ಮಾತ್ರ ಮಾನ್ಯವಾಗಿದೆ',
        share: 'ವಾಟ್ಸ್‌ಆಪ್‌ನಲ್ಲಿ ಹಂಚಿಕೊಳ್ಳಿ',
        copied: 'ನಕಲಿಸಲಾಗಿದೆ!',
        invalid: 'ಅಮಾನ್ಯ ಕೋಡ್!'
    },
    history: {
        no_history: 'ಯಾವುದೇ ಇತಿಹಾಸ ಇಲ್ಲ.',
        detail: {
            remove: 'ಇತಿಹಾಸವನ್ನು ತೆಗೆದು ಹಾಕಿ',
            confirm: 'ಈ ವೈಶಿಷ್ಟ್ಯವು ಹಾಜರಾತಿ, ಇತಿಹಾಸ ಮತ್ತು ವರದಿಗಳನ್ನು ಪರಿಣಾಮ ಬೀರುತ್ತದೆ.'
        }
    },
    supplier: {
        add: {
            title: 'ವಂಡರ್ ಸೇರಿಸಿ'
        },
        edit: {
            title: 'ವಂಡರ್ ಸಂಪಾದಿಸಿ'
        },
        new: 'ಹೊಸ ವಂಡರ್',
        no_supplier: 'ಯಾವುದೇ ವಂಡರ್ ಲಭ್ಯವಿಲ್ಲ.',
        bank: {
            title: 'ಬ್ಯಾಂಕ್ ವಿವರಗಳು'
        },
        note: 'ಗಮನಗಳು',
        form: {
            name: {
                label: 'ವಂಡರ್ ಹೆಸರು',
                placeholder: 'ವಂಡರ್ ಹೆಸರನ್ನು ಹಾಕಿ',
                placheolder_not_mandatory: 'ಹೆಸರು ಹಾಕಿ (ಆಕಸ್ಮಿಕ)',
                error: {
                    required: 'ಹೆಸರು ಖಾಲಿ ಇರಬಾರದು'
                }
            },
            contact: {
                label: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ',
                placeholder: '91934554432',
                error: {
                    required: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ ಖಾಲಿ ಇರಬಾರದು',
                    invalid: 'ಅಮಾನ್ಯ ಸಂಖ್ಯೆಯು. ದೇಶದ ಕೋಡ್ ಸೇರಿಸುವುದು ಅಗತ್ಯ.'
                }
            },
            bank_name: {
                label: 'ಬ್ಯಾಂಕ್ ಹೆಸರು',
                placeholder: 'ಬ್ಯಾಂಕ್ ಹೆಸರನ್ನು ಹಾಕಿ'
            },
            bank_account_name: {
                label: 'ಖಾತೆ धारಕ ಹೆಸರು',
                placeholder: 'ಖಾತೆ ಧಾರಕ ಹೆಸರನ್ನು ಹಾಕಿ'
            },
            bank_account_number: {
                label: 'ಖಾತೆ ಸಂಖ್ಯೆ',
                placeholder: 'ಖಾತೆ ಸಂಖ್ಯೆಯನ್ನು ಹಾಕಿ'
            },
            note: {
                label: 'ಗಮನಗಳು',
                placeholder: 'ಗಮನಗಳನ್ನು ಸೇರಿಸಿ'
            }
        },
        choose: 'ವಂಡರ್ ಆಯ್ಕೆ ಮಾಡಿ'
    },
    customer: {
        add: {
            title: 'ಗ್ರಾಹಕವನ್ನು ಸೇರಿಸಿ'
        },
        edit: {
            title: 'ಗ್ರಾಹಕವನ್ನು ಸಂಪಾದಿಸಿ'
        },
        new: 'ಹೊಸ ಗ್ರಾಹಕ',
        no_customer: 'ಯಾವುದೇ ಗ್ರಾಹಕರಿಲ್ಲ.',
        note: 'ಅವಲೋಕನ',
        form: {
            name: {
                label: 'ಗ್ರಾಹಕರ ಹೆಸರು',
                placeholder: 'ಗ್ರಾಹಕರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
                placheolder_not_mandatory: 'ಹೆಸರು ನಮೂದಿಸಿ (ಆಪ್ಷನಲ್)',
                error: {
                    required: 'ಹೆಸರು ಖಾಲಿ ಇರಲು ಸಾಧ್ಯವಿಲ್ಲ'
                }
            },
            contact: {
                label: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ',
                placeholder: '91934554432',
                error: {
                    required: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ ಖಾಲಿ ಇರಲು ಸಾಧ್ಯವಿಲ್ಲ',
                    invalid: 'ಅಕ್ರಮ ಸಂಖ್ಯೆ. ದೇಶದ ಕೋಡ್ ಸೇರಿಸಬೇಕು'
                }
            },
            address: {
                label: 'ವಿಳಾಸ',
                placeholder: 'ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ'
            },
            note: {
                label: 'ಅವಲೋಕನ',
                placeholder: 'ಅವಲೋಕನಗಳನ್ನು ಸೇರಿಸಿ'
            }
        },
        choose: 'ಖರೀದಿದಾರರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ',
        report: {
            view: 'ಖರೀದಿದಾರ ವರದಿ ವೀಕ್ಷಿಸಿ',
            title: 'ಖರೀದಿದಾರ ವರದಿ',
            no_report: 'ಆಯ್ಕೆಯಾದ ತಾರೀಕುಗೆ ಖರೀದಿದಾರರ ಕುರಿತು ಯಾವುದೇ ವರದಿಗಳು ಇಲ್ಲ.',
            column: {
                name: 'ಹೆಸರು',
                total_transaction: 'ಒಟ್ಟು ವ್ಯವಹಾರಗಳು',
                total_amount: 'ಒಟ್ಟು ಮಾರಾಟ',
                total_profit: 'ಒಟ್ಟು ಲಾಭ'
            }
        }
    },
    confirm_delete: 'ನೀವು ಈ ಡೇಟಾವನ್ನು ಅಳಿಸಲು ಖಚಿತವಾಗಿದ್ದೀರಾ?',
    report: {
        title: 'ವರದಿ'
    },
    accounting: {
        title: 'ಖಾತೆ ನಿರ್ವಹಣೆ',
        view_report: 'ಹಣಕಾಸಿನ ವರದಿ ವೀಕ್ಷಿಸಿ',
        dashboard_title: 'ಹಣಕಾಸಿನ ವರದಿ',
        stats: 'ಮೌಲ್ಯಮಾಪನ',
        transaction_report: 'ಗತಿವಿಧಿ ವರದಿ',
        filter: {
            date: {
                placeholder: 'ತಾರೀಕು ಆಯ್ಕೆ ಮಾಡಿ',
                submit: 'ಅನ್ವಯಿಸಿ',
                cancel: 'ಫಿಲ್ಟರ್ ಮರುಹೊಂದಿಸಿ'
            },
            timeframe: {
                day: 'ದೈನಂದಿನ',
                week: 'ವಾರದ',
                month: 'ತಿಂಗಳ',
                year: 'ವರ್ಷದ'
            }
        },
        total_sales: 'ಒಟ್ಟು ಮಾರಾಟ',
        total_purchases: 'ಒಟ್ಟು ಖರೀದಿಗಳು',
        total_profit: 'ಒಟ್ಟು ಲಾಭ',
        assets_value: 'ಪ್ರಸ್ತುತ ಆಸ್ತಿ ಮೌಲ್ಯ',
        table: {
            date: 'ತಾರೀಕು',
            sales: 'ಮಾರಾಟ',
            purchases: 'ಖರೀದಿಗಳು',
            profit: 'ಲಾಭ'
        },
        no_data: 'ಯಾವುದೇ ಡೇಟಾ ಲಭ್ಯವಿಲ್ಲ.'
    },
    stock_report: {
        title: 'ಸ್ಟಾಕ್ ವರದಿ',
        view: 'ಸ್ಟಾಕ್ ವರದಿ ವೀಕ್ಷಿಸಿ',
        no_report: 'ಆಯ್ಕೆ ಮಾಡಿದ ತಾರೀಕು ಅಥವಾ ಸಿಬ್ಬಂದಿಗಾಗಿ ಯಾವುದೇ ಸ್ಟಾಕ್ ದಾಖಲೆಗಳಿಲ್ಲ.',
        allStaffPlaceHolder: 'ಎಲ್ಲಾ ಸಿಬ್ಬಂದಿ',
        column: {
            total_product: 'ಪ್ರಸ್ತುತ ಉತ್ಪನ್ನ ಪ್ರಕಾರಗಳು',
            total_product_quantity: 'ಒಟ್ಟು ಪ್ರಸ್ತುತ ಉತ್ಪನ್ನಗಳು'
        }
    },
    subscription: {
        paywall_small: {
            title: 'VIP ವೈಶಿಷ್ಟ್ಯ',
            description:
                'ಈ ವೈಶಿಷ್ಟ್ಯದ ತಾತ್ಕಾಲಿಕ ಅವಧಿ ಮುಗಿಯಿತು. ಸಬ್ಸ್ಕ್ರೈಬಿಂಗ್ ಮೂಲಕ ಈ ಸ್ಥಳೀಯ ಅಪ್ಲಿಕೇಶನ್ ಅಭಿವೃದ್ಧಿಗೆ ಬೆಂಬಲ ನೀಡಿ.',
            button: 'ಸಬ್ಸ್ಕ್ರಿಪ್ಷನ್ ಯೋಜನೆಗಳನ್ನು ವೀಕ್ಷಿಸಿ'
        }
    },
    payment: {
        order: {
            title: 'ಪಾವತಿ'
        }
    },
    imageUploadMessage: {
        uploadSize: 'ಚಿತ್ರದ ಗಾತ್ರ 5MB ದಾಟಬಾರದು!',
        failedMessage: 'ಆಯ್ಕೆಯ ಚಿತ್ರದ ಅಪ್‌ಲೋಡ್ ವಿಫಲವಾಗಿದೆ, ದಯವಿಟ್ಟು ಪುನಃ ಪ್ರಯತ್ನಿಸಿ!',
        successUpload: 'ಚಿತ್ರವು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಆಗಿದೆ!',
        failedUIError: 'ಚಿತ್ರವನ್ನು ಅಳಿಸಲು ವಿಫಲವಾಗಿದೆ, ದಯವಿಟ್ಟು ಪುನಃ ಪ್ರಯತ್ನಿಸಿ!',
        SuccessErrorImage: 'ಚಿತ್ರವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'ಉತ್ಪನ್ನದ ಹೆಸರು',
        categoryLabel: 'ವರ್ಗ',
        BuyingPriceLabel: 'ಖರೀದಿ ಬೆಲೆ',
        SellingLabel: 'ಮಾರಾಟ ಬೆಲೆ',
        StockLabel: 'ಸ್ಟಾಕ್'
    },
    historyColumns: {
        Transactionlabel: 'ಲೆನ್ಮೂಸು ಪ್ರಕಾರ',
        Locallabel: 'ಸ್ಥಳೀಯ ಸಮಯ',
        Createdlabel: 'ಸೃಷ್ಟಿಸಲಾಗಿದೆ',
        Vendorlabel: 'ಪೂರಕನ ಹೆಸರು',
        Buyerlabel: 'ಖರಿದಾರನ ಹೆಸರು',
        SKUlabel: 'SKU',
        Productlabel: 'ಉತ್ಪನ್ನದ ಹೆಸರು',
        Categorylabel: 'ವರ್ಗ',
        Buyinglabel: 'ಖರೀದಿ ಬೆಲೆ',
        Sellinglabel: 'ಮಾರಾಟ ಬೆಲೆ',
        Initiallabel: 'ಆರಂಭಿಕ ಸ್ಟಾಕ್',
        Finallabel: 'ಅಂತಿಮ ಸ್ಟಾಕ್',
        Stocklabel: 'ಸ್ಟಾಕ್ ಬದಲಾವಣೆ'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'ಉತ್ಪನ್ನದ ಹೆಸರು',
        categoryLabel: 'ವರ್ಗ',
        buyingPriceLabel: 'ಖರೀದಿ ಬೆಲೆ',
        sellingPriceLabel: 'ಮಾರಾಟ ಬೆಲೆ',
        stockInLabel: 'ಸ್ಟಾಕ್ ಒಳಗೆ',
        stockOutLabel: 'ಸ್ಟಾಕ್ ಹೊರಗೆ',
        remainingStockLabel: 'ಅಬಕ್ಕ ಸ್ಟಾಕ್'
    },
    accountingReportColumns: {
        labelDate: 'ತಾರೀಕು',
        labelSales: 'ಮಾರಾಟ',
        labelPurchases: 'ಖರೀದಿಗಳು',
        labelProfit: 'ಲಾಭ'
    },
    customerReportColumns: {
        labelName: 'ಹೆಸರು',
        labelTotalTransaction: 'ಒಟ್ಟು ಲೆನ್ಮೂಸು',
        labelTotalSales: 'ಒಟ್ಟು ಮಾರಾಟ',
        labelTotalProfit: 'ಒಟ್ಟು ಲಾಭ'
    },
    scannerDialog: {
        finished: 'ಮುಗಿಯಿತು',
        reduce: 'ಕಡಿಮೆ ಮಾಡು',
        successfull: 'ಯಶಸ್ವಿ',
        searching: 'ಹುಡುಕುತ್ತಿದೆ...',
        title: 'ಸ್ಕ್ಯಾನರ್ ಚಾಲನೆಯಲ್ಲಿದೆ. ಬಾರ್ಕೋಡ್‌ಗೆ ದಿಕ್ಕು ತೋರಿಸಿ!'
    },
    historyFilter: {
        SelectBuyer: 'ಖರಿದಾರನನ್ನು ಆಯ್ಕೆಮಾಡಿ',
        SelectVendor: 'ಪೂರಕನನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    productStockFilter: {
        StockLowtoHigh: 'ಸ್ಟಾಕ್ ಕಡಿಮೆದಿಂದ ಹೆಚ್ಚು',
        StockHightoLow: 'ಸ್ಟಾಕ್ ಹೆಚ್ಚುದಿಂದ ಕಡಿಮೆ',
        AlphabetAZ: 'ಅಕ್ಷರ A-Z',
        AlphabetZA: 'ಅಕ್ಷರ Z-A'
    },
    minimizeTitle: 'ಸ್ಕ್ಯಾನರ್ ಚಾಲನೆಯಲ್ಲಿದೆ',
    security_guarantee: {
        title: 'ದತ್ತಾಂಶ ಸುರಕ್ಷತೆ ಖಾತರಿಯಾಗಿದೆ'
    },
    invoice: {
        invoicePlaceholder: 'ಸರಕುಪಟ್ಟಿ ಸಂಖ್ಯೆ',
        invoiceTitle: 'ಸರಕುಪಟ್ಟಿ'
    },
    stocklowreport: {
        no_stocklowreport_one: 'ಸುರಕ್ಷಿತ ಸ್ಟಾಕ್ ಸೆಟ್ಟಿಂಗ್‌ಗಳೊಂದಿಗೆ ಯಾವುದೇ ಐಟಂಗಳಿಲ್ಲ.',
        no_stocklowreport_two: 'ದಯವಿಟ್ಟು ಸುರಕ್ಷಿತ ಸ್ಟಾಕ್ ಪ್ರಮಾಣವನ್ನು ಸೇರಿಸಿ.'
    },
    low_alert_stock: 'ಸುರಕ್ಷಿತ ಸ್ಟಾಕ್ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ',
    lowStockAlertNotify: 'ಸ್ಟಾಕ್ ನಿರ್ಧಾರಿತ ಪ್ರಮಾಣಕ್ಕಿಂತ ಕಡಿಮೆಯಾಗಿದೆಯಾದರೆ ನೀವು ಸೂಚನೆ ಪಡೆಯುತ್ತೀರಿ.',
    applyButtonStockAlert: 'ನೇರವಾಗಿರಿ',
    cancelButtonStockAlert: 'ರದ್ದುಮಾಡಿ',
    searchProductLabel: 'ಉತ್ಪನ್ನವನ್ನು ಹುಡುಕಿ...',
    successfullyAddedSafetyStock: 'ಸುರಕ್ಷಿತ ಸ್ಟಾಕ್ ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ',
    safety_Stock: 'ಭದ್ರತಾ ಸ್ಟಾಕ್',
    safety_stock_placeholder: 'ಭದ್ರತಾ ಸ್ಟಾಕ್ ನಮೂದಿಸಿ',
    lowStockDetailsInfo: {
        ProductCode: 'ಉತ್ಪನ್ನ ಕೋಡ್',
        SafetyStock: 'ಭದ್ರತಾ ಸ್ಟಾಕ್',
        Stock: 'ಸ್ಟಾಕ್',
        CostPrice: 'ವೆಚ್ಚದ ಬೆಲೆ',
        SellingPrice: 'ಮಾರಾಟದ ಬೆಲೆ',
        Category: 'ವರ್ಗ',
        Description: 'ವಿವರಣೆ',
        ExpirationDate: 'ಅವಧಿ ಮುಗಿಯುವ ದಿನಾಂಕ'
    },
    CustomLang: {
        Attribute: 'ಗುಣ',
        AddAttribute: 'ಗುಣ ಸೇರಿಸಿ',
        EditAttribute: 'ಗುಣ ಸಂಪಾದಿಸಿ',
        CustomLabePlaceholder: 'ಗುಣದ ಹೆಸರು ನಮೂದಿಸಿ',
        CustomLabePlaceholderSelect: 'ಪ್ರಕಾರ ಆಯ್ಕೆಮಾಡಿ',
        AddMoreAttribute: 'ಹೆಚ್ಚುವರಿ ಗುಣಗಳನ್ನು ಸೇರಿಸಿ',
        ItemAttribute: 'ವಸ್ತು ಗುಣ',
        TableName: 'ಹೆಸರು',
        TableType: 'ಪ್ರಕಾರ',
        TableAction: 'ಚಟುವಟಿಕೆ',
        TableDelete: 'ಮುಗಿಯಿಸಿ',
        TableEdit: 'ಸಂಪಾದಿಸಿ',
        deleteMessage: 'ಗುಣ ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ',
        editSuccessMessage: 'ಗುಣ ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ',
        AddSuccessMessage: 'ಗುಣ ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ',
        AddAtt: 'ಸೇರಿಸಿ',
        EditAtt: 'ಸಂಪಾದಿಸಿ',
        SubmitAtt: 'ಸಲ್ಲಿಸಲಾಗುತ್ತಿದೆ...'
    }
}

export default kn
