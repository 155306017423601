const common = {
    no_data: 'कोई डेटा उपलब्ध नहीं है',
    header: {
        business_name: 'आपका व्यवसाय'
    },
    download_app: {
        text: 'केलोला वेब संस्करण का उपयोग करने के लिए, कृपया लैपटॉप या पीसी का उपयोग करें।',
        button: 'मोबाइल संस्करण डाउनलोड करें'
    },
    unknown: 'अज्ञात',
    request_error:
        'एक त्रुटि हुई है। कृपया पुनः प्रयास करें! या अपनी समस्या की रिपोर्ट करने के लिए ग्राहक सहायता से संपर्क करें।',
    home: 'मुखपृष्ठ',
    product: 'उत्पाद',
    add_product: 'उत्पाद जोड़ें',
    edit_product: 'उत्पाद संपादित करें',
    stock_in: 'स्टॉक अंदर',
    Sales_Turnover: 'बिक्री कारोबार',
    profit: 'मुनाफा',
    stock_out: 'स्टॉक बाहर',
    staff: 'कर्मचारी',
    stock_audit: 'स्टॉक ऑडिट',
    button: {
        save: 'सहेजें',
        save_and_add_more: 'सहेजें और अधिक जोड़ें',
        submit: 'प्रस्तुत करें',
        done: 'समाप्त',
        edit: 'संपादित करें',
        save_edit: 'परिवर्तन सहेजें',
        yes: 'हां',
        clear: 'साफ करें',
        delete: 'हटाएं',
        cancel: 'रद्द करें'
    },
    select_date: 'तारीख चुनें',
    select_action: 'कार्रवाई चुनें',
    mandatory: ' *(आवश्यक)',
    not_mandatory: '(अनिवार्य नहीं)',
    business: 'व्यवसाय',
    account: 'खाता',
    profile: 'प्रोफ़ाइल',
    setting: 'सेटिंग्स',
    whichLanguage: 'हिन्दी',
    headerDashboard: 'VIP बनें',
    contact_us: 'हमसे संपर्क करें',
    logout: 'लॉग आउट',
    category: 'श्रेणी',
    data: 'डेटा',
    history: 'इतिहास',
    lowStockAlert: 'कम स्टॉक चेतावनी',
    lowStockAlertButton: 'सुरक्षा स्टॉक मात्रा जोड़ें',
    message: {
        login_success: 'सफलतापूर्वक लॉगिन हुआ!',
        onboard_success: 'बधाई हो!! आपकी यात्रा शुरू हो गई है!',
        successfully_saved: 'डेटा सफलतापूर्वक सहेजा गया',
        successfully_deleted: 'डेटा सफलतापूर्वक हटाया गया',
        error_required_field: 'आवश्यक फ़ील्ड खाली नहीं हो सकती',
        no_access: 'आपको पहुँच नहीं है',
        error_update_history: 'इतिहास अपडेट करने में विफल रहा'
    },
    feedback: 'प्रतिक्रिया दें',
    contact: 'संपर्क करें',
    add: 'जोड़ें',
    supplier: 'आपूर्तिकर्ता',
    customer: 'ग्राहक',
    random: 'अनियमित',
    scan: 'स्कैन करें',
    enter: 'बारकोड स्कैन करके लॉगिन करें',
    terms: {
        text: 'जारी रखने का मतलब है कि आप सहमत हैं',
        link: 'गोपनीयता नीति'
    },
    others: 'अन्य',
    calender: {
        presets: {
            today: 'आज',
            yesterday: 'कल',
            last_7_days: 'पिछले 7 दिन',
            last_30_days: 'पिछले 30 दिन',
            this_month: 'इस महीने',
            last_month: 'पिछले महीने',
            custom_range: 'तिथि चुनें'
        }
    },

    print_large: 'बड़ा प्रिंट (A4)',
    print_dot_matrix: 'बड़ा प्रिंट (डॉट मैट्रिक्स)',
    print_medium: 'मध्यम प्रिंट (75mm)',
    print_small: 'छोटा प्रिंट (48mm)',
    view_invoice: 'चालान',
    price: 'मूल्य',
    stock: 'स्टॉक',
    quantity: 'मात्रा',
    total: 'कुल',
    print_footer_left: 'द्वारा निर्मित',
    print_footer_center: 'मुद्रित तिथि',
    print_footer_date: 'को',
    print_to: 'प्राप्तकर्ता',
    language: 'हिन्दी',
    dateStatus: 'पिछले 7 दिन',
    placeholder: 'लेन-देन का प्रकार',
    staffHistory: 'स्टाफ चुनें',
    languageTitle: 'हिंदी',
    date: 'तारीख',
    download: 'डाउनलोड',
    download_excel: 'एक्सेल डाउनलोड करें',
    Selectperiod: 'अवधि चुनें',
    TransactionType: 'लेन-देन का प्रकार',
    SelectCustomer: 'ग्राहक चुनें',
    invoice: 'चालान',
    login: {
        google: 'Google से साइन इन करें',
        apple: 'Apple के साथ लॉगिन करें'
    },
    trusted_by: {
        title: 'इंडोनेशिया 🇮🇩 भर में 50,000 से अधिक व्यवसायों द्वारा भरोसा किया गया'
    },
    privacyPolicyOne: 'साइन इन करने पर, आप Simply की',
    privacyPolicyTwo: 'गोपनीयता नीति',
    privacyPolicyThree: 'से सहमत होते हैं।'
}

export default common
