const common = {
    no_data: 'ದತ್ತಾಂಶ ಲಭ್ಯವಿಲ್ಲ',
    header: {
        business_name: 'ನಿಮ್ಮ ವ್ಯಾಪಾರ'
    },
    download_app: {
        text: 'Simply ವೆಬ್ ಆವೃತ್ತಿಯನ್ನು ಬಳಸಲು, ದಯವಿಟ್ಟು ಲಾಪ್‌ಟಾಪ್ ಅಥವಾ ಪಿಸಿಯನ್ನು ಬಳಸಿರಿ.',
        button: 'ಮೊಬೈಲ್ ಆವೃತ್ತಿಯನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ'
    },
    unknown: 'ಅಜ್ಞಾನ',
    request_error:
        'ಒಂದು ದೋಷ ಸಂಭವಿತವಾಗಿದೆ. ದಯವಿಟ್ಟು ಮರು ಪ್ರಯತ್ನಿಸಿ! ಅಥವಾ ನಿಮ್ಮ ಸಮಸ್ಯೆಯನ್ನು ವರದಿಸಲು ಗ್ರಾಹಕ ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ.',
    home: 'ಮುಖಪುಟ',
    product: 'ಉತ್ಪನ್ನ',
    add_product: 'ಉತ್ಪನ್ನವನ್ನು ಸೇರಿಸಿ',
    edit_product: 'ಉತ್ಪನ್ನವನ್ನು ಸಂಪಾದಿಸಿ',
    stock_in: 'ಸ್ಟಾಕ್ ಒಳಗೆ',
    Sales_Turnover: 'ಮಾಲಿಕರ ಮಾರಾಟ',
    profit: 'ಲಾಭ',
    stock_out: 'ಸ್ಟಾಕ್ ಹೊರಗೆ',
    staff: 'ಸಿಬ್ಬಂದಿ',
    stock_audit: 'ಸ್ಟಾಕ್ ಪರಿಶೀಲನೆ',
    button: {
        save: 'ಉಳಿಸು',
        save_and_add_more: 'ಉಳಿಸಿ & ಇನ್ನಷ್ಟು ಸೇರಿಸಿ',
        submit: 'ಸಲ್ಲಿಸಿ',
        done: 'ಮುಗಿಯಿತು',
        edit: 'ಸಂಪಾದಿಸಿ',
        save_edit: 'ಮರುಸಂಪಾದನೆಗಳನ್ನು ಉಳಿಸಿ',
        yes: 'ಹೌದು',
        clear: 'ಕ್ಲೀರ್',
        delete: 'ಅಳಿಸಿ',
        cancel: 'ರದ್ದುಗೊಳಿಸು',
        reset: 'ಪುನರಾರಂಭ'
    },
    select_date: 'ತಾರೀಕು ಆಯ್ಕೆಮಾಡಿ',
    select_action: 'ಕ್ರಿಯೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    mandatory: ' *(ಅವಶ್ಯಕ)',
    not_mandatory: '(ಅವಶ್ಯಕವಿಲ್ಲ)',
    business: 'ವ್ಯಾಪಾರ',
    account: 'ಖಾತೆ',
    profile: 'ಪ್ರೊಫೈಲ್',
    setting: 'ಸೆಟ್ಟಿಂಗ್‌ಗಳು',
    whichLanguage: 'ಕನ್ನಡ',
    headerDashboard: 'ವಿ.ಐ.ಪಿ ಆಗಿ ಬೆಳೆದಿರಿ',
    contact_us: 'ನಮಗೆ ಸಂಪರ್ಕಿಸಿ',
    logout: 'ಲಾಗ್‌ಔಟ್',
    category: 'ವರ್ಗ',
    data: 'ದತ್ತಾಂಶ',
    history: 'ಇತಿಹಾಸ',
    lowStockAlert: 'ಕಡಿಮೆ ಸ್ಟಾಕ್ ಎಚ್ಚರಿಕೆ',
    lowStockAlertButton: 'ಭದ್ರತಾ ಸ್ಟಾಕ್ ಪ್ರಮಾಣವನ್ನು ಸೇರಿಸಿ',
    message: {
        login_success: 'ಯಶಸ್ವಿಯಾಗಿ ಲಾಗ್‌ಇನ್ ಆಯ್ತು!',
        onboard_success: 'ಹೂರೇ!! ನಿಮ್ಮ ಪ್ರಯಾಣ ಆರಂಭವಾಗಿದೆ!',
        successfully_saved: 'ದತ್ತಾಂಶ ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಯಿತು',
        successfully_deleted: 'ದತ್ತಾಂಶ ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಯಿತು',
        error_required_field: 'ಅವಶ್ಯಕ ಕ್ಷೇತ್ರ ಖಾಲಿ ಇರಬಾರದು',
        no_access: 'ಪರವಾನಾ ಇಲ್ಲ',
        error_update_history: 'ಇತಿಹಾಸವನ್ನು ನವೀಕರಿಸಲು ವಿಫಲವಾಗಿದೆ'
    },
    feedback: 'ಪ್ರತ್ಯಾಯ ನೀಡಿ',
    contact: 'ಸಂಪರ್ಕ',
    add: 'ಸೇರಿಸು',
    supplier: 'ಪೂರಕ',
    customer: 'ಗ್ರಾಹಕ',
    random: 'ಯಾದೃಚ್ಛಿಕ',
    scan: 'ಸ್ಕಾನ್ ಮಾಡಿ',
    enter: 'ಬಾರ್ಕೋಡ್ ಸ್ಕಾನ್ ಮಾಡುವ ಮೂಲಕ ಲಾಗಿನ್ ಮಾಡಿ',
    terms: {
        text: 'ಮುಂದುವರಿಯಲು, ನೀವು ಒಪ್ಪಿಗೆಯಾದದ್ದು',
        link: 'ಗೋಪ್ಯತಾ ನೀತಿ'
    },
    others: 'ಇತರಗಳು',
    calender: {
        presets: {
            today: 'ಇಂದು',
            yesterday: 'ನಿನ್ನೆ',
            last_7_days: 'ಕಳೆದ 7 ದಿನಗಳು',
            last_30_days: 'ಕಳೆದ 30 ದಿನಗಳು',
            this_month: 'ಈ ತಿಂಗಳು',
            last_month: 'ಕಳೆದ ತಿಂಗಳು',
            custom_range: 'ಕಸ್ಟಮ್ ಶ್ರೇಣಿಯ'
        }
    },
    print_large: 'ಹೆಚ್ಚುವರಿ ಮುದ್ರಣ (A4)',
    print_dot_matrix: 'ದೊಡ್ಡ ಮುದ್ರಣ (ಡಾಟ್ ಮ್ಯಾಟ್ರಿಕ್)',
    print_medium: 'ಮಧ್ಯಮ ಮುದ್ರಣ (75mm)',
    print_small: 'ಚಿಕ್ಕ ಮುದ್ರಣ (48mm)',
    view_invoice: 'ಪರಿಶುದ್ಧಿ',
    price: 'ಬೆಲೆ',
    stock: 'ಸ್ಟಾಕ್',
    quantity: 'ಮಟ್ಟ',
    total: 'ಒಟ್ಟು',
    print_footer_left: 'ಸೃಷ್ಟಿಸಲಾಗಿದೆ',
    print_footer_center: 'ಮುದ್ರಣ ಮಾಡಿದ ದಿನಾಂಕ',
    print_footer_date: 'ದಿನಾಂಕದಲ್ಲಿ',
    print_to: 'ಗೆ',
    language: 'ಕನ್ನಡ',
    dateStatus: 'ಕಳೆದ 7 ದಿನಗಳು',
    placeholder: 'ಊರಿನ ಪ್ರಕಾರ',
    staffHistory: 'ಸಿಬ್ಬಂದಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    languageTitle: 'ಕನ್ನಡ',
    date: 'ತಾರೀಕು',
    download: 'ಡೌನ್‌ಲೋಡ್',
    download_excel: 'ಎಕ್ಸೆಲ್ ಡೌನ್‌ಲೋಡ್',
    currentTitle: 'ಮಾಲೀಕೆ',
    Selectperiod: 'ಶ್ರೇಣಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    TransactionType: 'ಲೆನ್ಮೂಸನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    SelectCustomer: 'ಗ್ರಾಹಕವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    invoice: 'ಪರಿಶುದ್ಧಿ',
    login: {
        google: 'ಗೂಗಲ್ ಮೂಲಕ ಲಾಗಿನ್ ಮಾಡಿ',
        apple: 'ಆಪಲ್ ಮೂಲಕ ಲಾಗಿನ್ ಮಾಡಿ'
    },
    trusted_by: {
        title: '50,000ಕ್ಕೂ ಹೆಚ್ಚು ವ್ಯಾಪಾರಗಳಿಂದ ವಿಶ್ವಾಸವನ್ನು ಪಡೆದಿದೆ \n ಇಂಡೋನೆಷ್ಯಾದಾದ್ಯಂತ 🇮🇩'
    },
    privacyPolicyOne: 'ನೀವು ಲಾಗ್ ಇನ್ ಮಾಡಿದಾಗ, ನೀವು Simply ಯ',
    privacyPolicyTwo: 'ಗೌಪ್ಯತಾ ನೀತಿ',
    privacyPolicyThree: 'ಗೆ ಒಪ್ಪುತ್ತೀರಿ.'
}

export default common
